import { PMChartVersionTypeEnum, PMChartBpcStatusEnum, PMChartStatusEnum } from '@/ship/Enums/ProjectManagementEnums';
import IDictionary from '@/ship/Models/IDictionary';
import { IPMChart, IPMChartBpc, IPMChartVersion, IPMCity } from '@/ship/Models/IProjectManagement';

export const defaultBpc: IDictionary = {
    status: PMChartBpcStatusEnum.prepared,
    rows: [
        {
            name: 'Выполнение основных инженерных изысканий',
            code: '3',
            startDate: '2024-01-11',
            endDate: '2024-02-11',
            childTasks: {
                data: [
                    {
                        name: 'Выполнение основных инженерных изысканий',
                        code: '3.1',
                        startDate: '2024-02-12',
                        endDate: '2024-03-12',
                        childTasks: {
                            data: [
                                {
                                    name: 'Выполнение основных инженерных изысканий',
                                    code: '3.1.2',
                                    startDate: '2024-03-13',
                                    endDate: '2024-03-13',
                                    childTasks: [],
                                },
                            ],
                        },
                    },
                    {
                        name: 'Выполнение основных инженерных изысканий',
                        code: '3.2',
                        startDate: '2024-02-12',
                        endDate: '2024-03-12',
                        childTasks: {},
                    },
                    {
                        name: 'Монолитные работы в т.ч. ростверки бетонная подготовка',
                        code: '3.3',
                        startDate: '2024-02-12',
                        endDate: '2024-03-12',
                        childTasks: {
                            data: [
                                {
                                    name: 'Выполнение основных инженерных изысканий',
                                    code: '3.3.1',
                                    startDate: '2024-03-13',
                                    endDate: '2024-03-13',
                                    childTasks: {
                                        data: [
                                            {
                                                name: 'Выполнение основных инженерных изысканий',
                                                code: '3.3.1',
                                                startDate: '2024-03-13',
                                                endDate: '2024-03-13',
                                                childTasks: {
                                                    data: [
                                                        {
                                                            name: 'Кладка наружных и внутренних стен, перегородок',
                                                            code: '3.3.1.1',
                                                            startDate: '2024-03-13',
                                                            endDate: '2024-03-13',
                                                            childTasks: {},
                                                        },
                                                        {
                                                            name: 'Гидроизоляция и утепление подвала. Обратная засыпка пахух',
                                                            code: '3.3.1.2',
                                                            startDate: '2024-03-13',
                                                            endDate: '2024-03-13',
                                                            childTasks: {},
                                                        },
                                                        {
                                                            name: 'Монтаж систем внутреннего электроснабжения и электроотведения',
                                                            code: '3.3.1.3',
                                                            startDate: '2024-03-13',
                                                            endDate: '2024-03-13',
                                                            childTasks: {},
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};
export const defaultDpcExecTasks: IDictionary = {
    data: {
        data: {
            status: PMChartBpcStatusEnum.prepared,
            tasks: {
                data: [
                    {
                        id: 1,
                        name: 'Выполнение основных инженерных изысканий',
                        code: '3',
                        completenessPlan: 100.0,
                        startDatePlan: '2024-01-11',
                        endDatePlan: '2024-02-11',
                        completenessFact: 100.0,
                        startDateFact: '2024-01-11',
                        endDateFact: '2024-02-11',
                        completenessGeneral: 100.0,
                        description: '',
                        childTasks: {
                            data: [
                                {
                                    id: 2,
                                    name: 'Выполнение основных инженерных изысканий',
                                    code: '3.1',
                                    completenessPlan: 100.0,
                                    startDatePlan: '2024-01-11',
                                    endDatePlan: '2024-02-11',
                                    completenessFact: 100.0,
                                    startDateFact: '2024-01-11',
                                    endDateFact: '2024-02-11',
                                    completenessGeneral: 100.0,
                                    description: '',
                                    childTasks: {
                                        data: [
                                            {
                                                name: 'Выполнение основных инженерных изысканий',
                                                code: '3.1.2',
                                                completenessPlan: 100.0,
                                                startDatePlan: '2024-01-11',
                                                endDatePlan: '2024-02-11',
                                                completenessFact: 100.0,
                                                startDateFact: '2024-01-11',
                                                endDateFact: '2024-02-11',
                                                completenessGeneral: 100.0,
                                                description: '',
                                                childTasks: [],
                                            },
                                        ],
                                    },
                                },
                                {
                                    id: 3,
                                    name: 'Выполнение основных инженерных изысканий',
                                    code: '3.2',
                                    completenessPlan: 100.0,
                                    startDatePlan: '2024-01-11',
                                    endDatePlan: '2024-02-11',
                                    completenessFact: 100.0,
                                    startDateFact: '2024-01-11',
                                    endDateFact: '2024-02-11',
                                    completenessGeneral: 100.0,
                                    description: '',
                                    childTasks: {},
                                },
                                {
                                    id: 4,
                                    name: 'Монолитные работы в т.ч. ростверки бетонная подготовка',
                                    code: '3.3',
                                    completenessPlan: 100.0,
                                    startDatePlan: '2024-01-11',
                                    endDatePlan: '2024-02-11',
                                    completenessFact: 100.0,
                                    startDateFact: '2024-01-11',
                                    endDateFact: '2024-02-11',
                                    completenessGeneral: 100.0,
                                    description: '',
                                    childTasks: {
                                        data: [
                                            {
                                                id: 5,
                                                name: 'Выполнение основных инженерных изысканий',
                                                code: '3.3.1',
                                                completenessPlan: 100.0,
                                                startDatePlan: '2024-01-11',
                                                endDatePlan: '2024-02-11',
                                                completenessFact: 100.0,
                                                startDateFact: '2024-01-11',
                                                endDateFact: '2024-02-11',
                                                completenessGeneral: 100.0,
                                                description: '',
                                                childTasks: {
                                                    data: [
                                                        {
                                                            id: 6,
                                                            name: 'Выполнение основных инженерных изысканий',
                                                            code: '3.3.1',
                                                            completenessPlan: 100.0,
                                                            startDatePlan: '2024-01-11',
                                                            endDatePlan: '2024-02-11',
                                                            completenessFact: 100.0,
                                                            startDateFact: '2024-01-11',
                                                            endDateFact: '2024-02-11',
                                                            completenessGeneral: 100.0,
                                                            description: '',
                                                            childTasks: {
                                                                data: [
                                                                    {
                                                                        id: 7,
                                                                        name: 'Кладка наружных и внутренних стен, перегородок',
                                                                        code: '3.3.1.1',
                                                                        completenessPlan: 100.0,
                                                                        startDatePlan: '2024-01-11',
                                                                        endDatePlan: '2024-02-11',
                                                                        completenessFact: 100.0,
                                                                        startDateFact: '2024-01-11',
                                                                        endDateFact: '2024-02-11',
                                                                        completenessGeneral: 100.0,
                                                                        description: '',
                                                                        childTasks: {},
                                                                    },
                                                                    {
                                                                        id: 8,
                                                                        name: 'Гидроизоляция и утепление подвала. Обратная засыпка пахух',
                                                                        code: '3.3.1.2',
                                                                        completenessPlan: 100.0,
                                                                        startDatePlan: '2024-01-11',
                                                                        endDatePlan: '2024-02-11',
                                                                        completenessFact: 100.0,
                                                                        startDateFact: '2024-01-11',
                                                                        endDateFact: '2024-02-11',
                                                                        completenessGeneral: 100.0,
                                                                        description: '',
                                                                        childTasks: {},
                                                                    },
                                                                    {
                                                                        id: 9,
                                                                        name: 'Монтаж систем внутреннего электроснабжения и электроотведения',
                                                                        code: '3.3.1.3',
                                                                        completenessPlan: 100.0,
                                                                        startDatePlan: '2024-01-11',
                                                                        endDatePlan: '2024-02-11',
                                                                        completenessFact: 100.0,
                                                                        startDateFact: '2024-01-11',
                                                                        endDateFact: '2024-02-11',
                                                                        completenessGeneral: 100.0,
                                                                        description: '',
                                                                        childTasks: {},
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    },
};
export const defaultWeeklyReportJobs: IDictionary = {
    status: PMChartBpcStatusEnum.prepared,
    rows: [
        {
            id: 1,
            name: 'Выполнение основных инженерных изысканий',
            code: '3',
            number: 1,
            percentReadyPlan: 100,
            percentReadyFact: 88.5,
            addedPhotos: 0,
            description:
                'Устройство внутренних перегородок и балконов. Устройство внутренних перегородок и балконов. Устройство внутренних перегородок и балконов. Столбец описание работ растягивается на всю ширину экрана (Fill). Максимально в ячейку влазит 2 строки во всю ширину. Всё, что не влезет, уходит в “...” (3 точки)',
            childTasks: {
                data: [
                    {
                        id: 2,
                        name: 'Выполнение основных инженерных изысканий',
                        code: '3.1',
                        number: 2,
                        percentReadyPlan: 100,
                        percentReadyFact: 90.5,
                        addedPhotos: 1,
                        description: 'Устройство внутренних перегородок и балконов',
                        childTasks: {
                            data: [
                                {
                                    name: 'Выполнение основных инженерных изысканий',
                                    code: '3.1.2',
                                    number: 4,
                                    percentReadyPlan: 100,
                                    percentReadyFact: 90.5,
                                    addedPhotos: 2,
                                    description: 'Устройство внутренних перегородок и балконов',
                                    childTasks: [],
                                },
                            ],
                        },
                    },
                    {
                        id: 3,
                        name: 'Выполнение основных инженерных изысканий',
                        code: '3.2',
                        number: 5,
                        percentReadyPlan: 100,
                        percentReadyFact: 90.5,
                        addedPhotos: 1,
                        description: 'Устройство внутренних перегородок и балконов',
                        childTasks: {},
                    },
                    {
                        id: 4,
                        name: 'Монолитные работы в т.ч. ростверки бетонная подготовка',
                        code: '3.3',
                        number: 6,
                        percentReadyPlan: 100,
                        percentReadyFact: 90.5,
                        addedPhotos: 1,
                        description: 'Устройство внутренних перегородок и балконов',
                        childTasks: {
                            data: [
                                {
                                    id: 5,
                                    name: 'Выполнение основных инженерных изысканий',
                                    code: '3.3.1',
                                    number: 7,
                                    percentReadyPlan: 100,
                                    percentReadyFact: 90.5,
                                    addedPhotos: 1,
                                    description: 'Устройство внутренних перегородок и балконов',
                                    childTasks: {
                                        data: [
                                            {
                                                id: 6,
                                                name: 'Выполнение основных инженерных изысканий',
                                                code: '3.3.1',
                                                number: 8,
                                                percentReadyPlan: 100,
                                                percentReadyFact: 90.5,
                                                addedPhotos: 1,
                                                description: 'Устройство внутренних перегородок и балконов',
                                                childTasks: {
                                                    data: [
                                                        {
                                                            id: 7,
                                                            name: 'Кладка наружных и внутренних стен, перегородок',
                                                            code: '3.3.1.1',
                                                            number: 9,
                                                            percentReadyPlan: 100,
                                                            percentReadyFact: 90.5,
                                                            addedPhotos: 1,
                                                            description: 'Устройство внутренних перегородок и балконов',
                                                            childTasks: {},
                                                        },
                                                        {
                                                            id: 8,
                                                            name: 'Гидроизоляция и утепление подвала. Обратная засыпка пахух',
                                                            code: '3.3.1.2',
                                                            number: 11,
                                                            percentReadyPlan: 100,
                                                            percentReadyFact: 90.5,
                                                            addedPhotos: 1,
                                                            description: 'Устройство внутренних перегородок и балконов',
                                                            childTasks: {},
                                                        },
                                                        {
                                                            id: 9,
                                                            name: 'Монтаж систем внутреннего электроснабжения и электроотведения',
                                                            code: '3.3.1.3',
                                                            number: 12,
                                                            percentReadyPlan: 100,
                                                            percentReadyFact: 90.5,
                                                            addedPhotos: 1,
                                                            description: 'Устройство внутренних перегородок и балконов',
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};

export const defaultReportDeadlines: IDictionary = {
    status: PMChartBpcStatusEnum.prepared,
    rows: [
        {
            id: 1,
            name: 'Выполнение основных инженерных изысканий',
            code: '3',
            durationGeneral: '356 к. дн.',
            startDate: '2023-08-21',
            endDate: '2024-08-21',
            startDateBaseSchedule: '2023-08-21',
            endDateBaseSchedule: '2023-08-21',
            durationWorkingDays: '356 р. дн.',
            durationPredict: '356 р. дн.',
            completenessGeneral: 100,
            completenessPredict: 100,
            completenessFact: 100,
            stage: 'Основной период строительства',
            startDeviation: 0,
            endDeviation: 0,
            childTasks: {
                data: [
                    {
                        id: 2,
                        name: 'Выполнение основных инженерных изысканий',
                        code: '3.1',
                        durationGeneral: '356 к. дн.',
                        startDate: '2023-08-21',
                        endDate: '2024-08-21',
                        startDateBaseSchedule: '2023-08-21',
                        endDateBaseSchedule: '2023-08-21',
                        durationWorkingDays: '356 р. дн.',
                        durationPredict: '356 р. дн.',
                        completenessGeneral: 100,
                        completenessPredict: 100,
                        completenessFact: 100,
                        stage: 'Основной период строительства',
                        startDeviation: 0,
                        endDeviation: 0,
                        childTasks: {
                            data: [
                                {
                                    name: 'Выполнение основных инженерных изысканий',
                                    code: '3.1.2',
                                    durationGeneral: '356 к. дн.',
                                    startDate: '2023-08-21',
                                    endDate: '2024-08-21',
                                    startDateBaseSchedule: '2023-08-21',
                                    endDateBaseSchedule: '2023-08-21',
                                    durationWorkingDays: '356 р. дн.',
                                    durationPredict: '356 р. дн.',
                                    completenessGeneral: 100,
                                    completenessPredict: 100,
                                    completenessFact: 100,
                                    stage: 'Основной период строительства',
                                    startDeviation: 0,
                                    endDeviation: 0,
                                    childTasks: [],
                                },
                            ],
                        },
                    },
                    {
                        id: 3,
                        name: 'Выполнение основных инженерных изысканий',
                        code: '3.2',
                        durationGeneral: '356 к. дн.',
                        startDate: '2023-08-21',
                        endDate: '2024-08-21',
                        startDateBaseSchedule: '2023-08-21',
                        endDateBaseSchedule: '2023-08-21',
                        durationWorkingDays: '356 р. дн.',
                        durationPredict: '356 р. дн.',
                        completenessGeneral: 100,
                        completenessPredict: 100,
                        completenessFact: 100,
                        stage: 'Основной период строительства',
                        startDeviation: 0,
                        endDeviation: 0,
                        childTasks: {},
                    },
                    {
                        id: 4,
                        name: 'Монолитные работы в т.ч. ростверки бетонная подготовка',
                        code: '3.3',
                        durationGeneral: '356 к. дн.',
                        startDate: '2023-08-21',
                        endDate: '2024-08-21',
                        startDateBaseSchedule: '2023-08-21',
                        endDateBaseSchedule: '2023-08-21',
                        durationWorkingDays: '356 р. дн.',
                        durationPredict: '356 р. дн.',
                        completenessGeneral: 100,
                        completenessPredict: 100,
                        completenessFact: 100,
                        stage: 'Основной период строительства',
                        startDeviation: 0,
                        endDeviation: 0,
                        childTasks: {
                            data: [
                                {
                                    id: 5,
                                    name: 'Выполнение основных инженерных изысканий',
                                    code: '3.3.1',
                                    durationGeneral: '356 к. дн.',
                                    startDate: '2023-08-21',
                                    endDate: '2024-08-21',
                                    startDateBaseSchedule: '2023-08-21',
                                    endDateBaseSchedule: '2023-08-21',
                                    durationWorkingDays: '356 р. дн.',
                                    durationPredict: '356 р. дн.',
                                    completenessGeneral: 100,
                                    completenessPredict: 100,
                                    completenessFact: 100,
                                    stage: 'Основной период строительства',
                                    startDeviation: 0,
                                    endDeviation: 0,
                                    childTasks: {
                                        data: [
                                            {
                                                id: 6,
                                                name: 'Выполнение основных инженерных изысканий',
                                                code: '3.3.1',
                                                durationGeneral: '356 к. дн.',
                                                startDate: '2023-08-21',
                                                endDate: '2024-08-21',
                                                startDateBaseSchedule: '2023-08-21',
                                                endDateBaseSchedule: '2023-08-21',
                                                durationWorkingDays: '356 р. дн.',
                                                durationPredict: '356 р. дн.',
                                                completenessGeneral: 100,
                                                completenessPredict: 100,
                                                completenessFact: 100,
                                                stage: 'Основной период строительства',
                                                startDeviation: 0,
                                                endDeviation: 0,
                                                childTasks: {
                                                    data: [
                                                        {
                                                            id: 7,
                                                            name: 'Кладка наружных и внутренних стен, перегородок',
                                                            code: '3.3.1.1',
                                                            durationGeneral: '356 к. дн.',
                                                            startDate: '2023-08-21',
                                                            endDate: '2024-08-21',
                                                            startDateBaseSchedule: '2023-08-21',
                                                            endDateBaseSchedule: '2023-08-21',
                                                            durationWorkingDays: '356 р. дн.',
                                                            durationPredict: '356 р. дн.',
                                                            completenessGeneral: 100,
                                                            completenessPredict: 100,
                                                            completenessFact: 100,
                                                            stage: 'Основной период строительства',
                                                            startDeviation: 0,
                                                            endDeviation: 0,
                                                            childTasks: {},
                                                        },
                                                        {
                                                            id: 8,
                                                            name: 'Гидроизоляция и утепление подвала. Обратная засыпка пахух',
                                                            code: '3.3.1.2',
                                                            durationGeneral: '356 к. дн.',
                                                            startDate: '2023-08-21',
                                                            endDate: '2024-08-21',
                                                            startDateBaseSchedule: '2023-08-21',
                                                            endDateBaseSchedule: '2023-08-21',
                                                            durationWorkingDays: '356 р. дн.',
                                                            durationPredict: '356 р. дн.',
                                                            completenessGeneral: 100,
                                                            completenessPredict: 100,
                                                            completenessFact: 100,
                                                            stage: 'Основной период строительства',
                                                            startDeviation: 0,
                                                            endDeviation: 0,
                                                            childTasks: {},
                                                        },
                                                        {
                                                            id: 9,
                                                            name: 'Монтаж систем внутреннего электроснабжения и электроотведения',
                                                            code: '3.3.1.3',
                                                            durationGeneral: '356 к. дн.',
                                                            startDate: '2023-08-21',
                                                            endDate: '2024-08-21',
                                                            startDateBaseSchedule: '2023-08-21',
                                                            endDateBaseSchedule: '2023-08-21',
                                                            durationWorkingDays: '356 р. дн.',
                                                            durationPredict: '356 р. дн.',
                                                            completenessGeneral: 100,
                                                            completenessPredict: 100,
                                                            completenessFact: 100,
                                                            stage: 'Основной период строительства',
                                                            startDeviation: 0,
                                                            endDeviation: 0,
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};

export const defaultChart: IPMChart = {
    id: 1,
    calendarType: 5,
    name: '1.3 Проектирование 02А3 Конди Литер 3 стадия П (дом)',
    status: PMChartStatusEnum.stopped,
    stage: 'Stage',
    managerId: 32,
    designerId: 32,
    startedAt: '2024-01-10',
    createdAt: '2024-01-10',
    updatedAt: '2024-01-10',
    projectId: 1,
    type: 1,
};

export const defaultChart2: IPMChart = {
    id: 1,
    calendarType: 5,
    name: '1.4 Проектирование 02А3 Конди Литер 4 стадия П (дом)',
    status: PMChartStatusEnum.stopped,
    stage: 'Stage',
    managerId: 32,
    designerId: 32,
    startedAt: '2024-01-10',
    createdAt: '2024-01-10',
    updatedAt: '2024-01-10',
    projectId: 1,
    type: 1,
};

export const defaultBpces: IPMChartBpc[] = [
    {
        acceptedDate: '2009-08-26T15:23:09.717Z',
        authorId: 32,
        branchDirectorId: 32,
        chartId: 1,
        constructionDirectorDeputyId: 32,
        createdAt: '2009-07-26T15:23:09.717Z',
        generalDirectorDeputyId: 32,
        id: 1,
        isBase: false,
        mainEngineerId: 32,
        name: 'Утвержденная версия №1',
        regionalHeadId: 32,
        status: PMChartBpcStatusEnum.prepared,
        updatedAt: '2009-07-27T15:23:09.717Z',
    },
    {
        id: 2,
        name: 'Утвержденная версия №2',
        isBase: false,
        acceptedDate: '2010-08-26T15:23:09.717Z',
        status: PMChartBpcStatusEnum.onApproval,
        createdAt: '2010-07-26T15:23:09.717Z',
        authorId: 98,
        chartId: 1,
        mainEngineerId: 32,
        branchDirectorId: 32,
        regionalHeadId: 32,
        constructionDirectorDeputyId: 32,
        generalDirectorDeputyId: 32,
        updatedAt: '2009-07-27T15:23:09.717Z',
    },
    {
        id: 3,
        name: 'Утвержденная версия №3',
        isBase: false,
        acceptedDate: '2011-08-26T15:23:09.717Z',
        status: PMChartBpcStatusEnum.agreed,
        createdAt: '2011-07-26T15:23:09.717Z',
        authorId: 99,
        chartId: 1,
        mainEngineerId: 32,
        branchDirectorId: 32,
        regionalHeadId: 32,
        constructionDirectorDeputyId: 32,
        generalDirectorDeputyId: 32,
        updatedAt: '2009-07-27T15:23:09.717Z',
    },
    {
        id: 4,
        name: 'Утвержденная версия 4',
        isBase: false,
        acceptedDate: '2012-08-26T15:23:09.717Z',
        status: PMChartBpcStatusEnum.notAgreed,
        createdAt: '2012-07-26T15:23:09.717Z',
        authorId: 99,
        chartId: 1,
        mainEngineerId: 32,
        branchDirectorId: 32,
        regionalHeadId: 32,
        constructionDirectorDeputyId: 32,
        generalDirectorDeputyId: 32,
        updatedAt: '2009-07-27T15:23:09.717Z',
    },
];

export const defaultDpcQuartes: IDictionary[] = [
    {
        id: 3456789,
        name: '25.08.2023 - 01.09.2023',
        status: PMChartBpcStatusEnum.prepared,
        createdAt: '2009-07-26T15:23:09.717Z',
        authorId: 32,
    },
    {
        id: 3456780,
        name: '25.08.2023 - 01.09.2023',
        status: PMChartBpcStatusEnum.onApproval,
        createdAt: '2010-07-26T15:23:09.717Z',
        authorId: 98,
    },
    {
        id: 3456781,
        name: '25.08.2023 - 01.09.2023',
        status: PMChartBpcStatusEnum.agreed,
        createdAt: '2011-07-26T15:23:09.717Z',
        authorId: 99,
    },
    {
        id: 3456782,
        name: '25.08.2023 - 01.09.2023',
        status: PMChartBpcStatusEnum.notAgreed,
        createdAt: '2012-07-26T15:23:09.717Z',
        authorId: 99,
    },
];

export const defaultDpcExecs: IDictionary[] = [
    {
        id: 3456789,
        name: '25.08.2023 - 01.09.2023',
        status: PMChartBpcStatusEnum.prepared,
        createdAt: '2009-07-26T15:23:09.717Z',
        authorId: 32,
    },
    {
        id: 3456780,
        name: '25.08.2023 - 01.09.2023',
        status: PMChartBpcStatusEnum.onApproval,
        createdAt: '2010-07-26T15:23:09.717Z',
        authorId: 98,
    },
    {
        id: 3456781,
        name: '25.08.2023 - 01.09.2023',
        status: PMChartBpcStatusEnum.agreed,
        createdAt: '2011-07-26T15:23:09.717Z',
        authorId: 99,
    },
    {
        id: 3456782,
        name: '25.08.2023 - 01.09.2023',
        status: PMChartBpcStatusEnum.notAgreed,
        createdAt: '2012-07-26T15:23:09.717Z',
        authorId: 99,
    },
];

export const defaultWeeklyReports: IDictionary[] = [
    {
        id: 3456789,
        name: '25.08.2023 - 01.09.2023',
        status: PMChartBpcStatusEnum.prepared,
        createdAt: '2009-07-26T15:23:09.717Z',
        authorId: 32,
        checkerId: 98,
    },
    {
        id: 3456780,
        name: '25.08.2023 - 01.09.2023',
        status: PMChartBpcStatusEnum.onApproval,
        createdAt: '2010-07-26T15:23:09.717Z',
        authorId: 98,
        checkerId: 32,
    },
    {
        id: 3456781,
        name: '25.08.2023 - 01.09.2023',
        status: PMChartBpcStatusEnum.agreed,
        createdAt: '2011-07-26T15:23:09.717Z',
        authorId: 99,
        checkerId: 32,
    },
    {
        id: 3456782,
        name: '25.08.2023 - 01.09.2023',
        status: PMChartBpcStatusEnum.notAgreed,
        createdAt: '2012-07-26T15:23:09.717Z',
        authorId: 99,
        checkerId: 98,
    },
];

export const defaultReportDpcExec: IDictionary[] = [
    { date: '2022-11-01', data: -0.5 },
    { date: '2022-11-08', data: -2.5 },
    { date: '2022-11-15', data: -0.5 },
    { date: '2022-11-22', data: -17.5 },
    { date: '2022-11-29', data: -23.0 },
    { date: '2022-12-06', data: -6 },
    { date: '2022-12-13', data: -0.5 },
    { date: '2022-12-20', data: -0.5 },
    { date: '2022-12-27', data: -0.5 },
    { date: '2023-01-03', data: -15 },
    { date: '2023-01-10', data: -10 },
    { date: '2023-01-17', data: -12 },
    { date: '2023-01-24', data: -0.5 },
    { date: '2023-01-31', data: -0.5 },
    { date: '2023-02-07', data: -0.5 },
    { date: '2023-02-14', data: -0.5 },
    { date: '2023-02-21', data: -0.5 },
    { date: '2023-02-28', data: -0.5 },
    { date: '2023-03-07', data: -5 },
    { date: '2023-03-14', data: -7.5 },
    { date: '2023-03-21', data: -20 },
    { date: '2023-03-28', data: -2.5 },
    { date: '2023-04-04', data: -15 },
    { date: '2023-04-11', data: -0.5 },
    { date: '2023-04-18', data: -0.5 },
    { date: '2023-04-25', data: -0.5 },
    { date: '2023-05-02', data: -2 },
    { date: '2023-05-09', data: -3 },
    { date: '2023-05-16', data: -5 },
    { date: '2023-05-23', data: -2 },
    { date: '2023-05-30', data: -0.5 },
];

export const defaultReportConstruction: IDictionary[] = [
    { date: '2022-11-01', data: 0.5 },
    { date: '2022-11-08', data: 2.5 },
    { date: '2022-11-15', data: 0.5 },
    { date: '2022-11-22', data: 17.5 },
    { date: '2022-11-29', data: 23.0 },
    { date: '2022-12-06', data: 6 },
    { date: '2022-12-13', data: 0.5 },
    { date: '2022-12-20', data: 0.5 },
    { date: '2022-12-27', data: 0.5 },
    { date: '2023-01-03', data: 15 },
    { date: '2023-01-10', data: 10 },
    { date: '2023-01-17', data: 12 },
    { date: '2023-01-24', data: 0.5 },
    { date: '2023-01-31', data: 0.5 },
    { date: '2023-02-07', data: 0.5 },
    { date: '2023-02-14', data: 0.5 },
    { date: '2023-02-21', data: 0.5 },
    { date: '2023-02-28', data: 0.5 },
    { date: '2023-03-07', data: 5 },
    { date: '2023-03-14', data: 7.5 },
    { date: '2023-03-21', data: 20 },
    { date: '2023-03-28', data: 2.5 },
    { date: '2023-04-04', data: 15 },
    { date: '2023-04-11', data: 0.5 },
    { date: '2023-04-18', data: 0.5 },
    { date: '2023-04-25', data: 0.5 },
    { date: '2023-05-02', data: 2 },
    { date: '2023-05-09', data: 3 },
    { date: '2023-05-16', data: 5 },
    { date: '2023-05-23', data: 2 },
    { date: '2023-05-30', data: 0.5 },
];

export const defaultDiagramData: IDictionary[] = [
    {
        name: 'Отклонение от буфера',
        data: [
            -0.5, -2.5, -0.5, -17.5, -23.0, -6, -0.5, -0.5, -0.5, -15, -10, -12, -0.5, -0.5, -0.5, -0.5, -0.5, -0.5, -5,
            -7.5, -20, -2.5, -15, -0.5, -0.5, -0.5, -2, -3, -5, -2, -0.5,
        ],
    },
];

export const defaultDiagramOptions: IDictionary = {
    chart: {
        type: 'bar',
        toolbar: {
            show: false,
        },
        defaultLocale: 'ru',
        locales: [
            {
                name: 'ru',
                options: {
                    months: [
                        'Январь',
                        'Февраль',
                        'Март',
                        'Апрель',
                        'Май',
                        'Июнь',
                        'Июль',
                        'Август',
                        'Сентябрь',
                        'Октябрь',
                        'Ноябрь',
                        'Декабрь',
                    ],
                    shortMonths: [
                        'Янв.',
                        'Фев.',
                        'Март',
                        'Апр.',
                        'Май',
                        'Июнь',
                        'Июль',
                        'Авг.',
                        'Сент.',
                        'Окт.',
                        'Нояб.',
                        'Дек.',
                    ],
                    days: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
                    shortDays: ['Вс.', 'Пн.', 'Вт.', 'Ср.', 'Чт.', 'Пт.', 'Суб.'],
                    toolbar: {
                        download: 'Скачать SVG',
                        selection: 'Выбор',
                        selectionZoom: 'Выбрать Zoom',
                        zoomIn: 'Приблизить',
                        zoomOut: 'Отдалить',
                        pan: 'Смещение',
                        reset: 'Сбросить масштаб',
                    },
                },
            },
            {
                name: 'en',
                options: {
                    months: [
                        'January',
                        'February',
                        'March',
                        'April',
                        'May',
                        'June',
                        'July',
                        'August',
                        'September',
                        'October',
                        'November',
                        'December',
                    ],
                    shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                    days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
                    shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                    toolbar: {
                        download: 'Download SVG',
                        selection: 'Selection',
                        selectionZoom: 'Selection Zoom',
                        zoomIn: 'Zoom In',
                        zoomOut: 'Zoom Out',
                        pan: 'Panning',
                        reset: 'Reset Zoom',
                    },
                },
            },
        ],
    },
    plotOptions: {
        bar: {
            colors: {
                ranges: [
                    {
                        from: -100,
                        to: 0,
                        color: '#30C07C',
                    },
                ],
            },
            columnWidth: '50%',
        },
    },
    dataLabels: {
        enabled: false,
    },
    yaxis: {
        title: {
            text: '',
        },
        labels: {
            offsetX: 0,
            offsetY: 5,
            style: {
                fontSize: '18px',
                fontFamily: 'Roboto',
            },
            formatter: function (y: number) {
                return y.toFixed(0);
            },
        },
        axisBorder: {
            show: true,
            color: '#78909C',
        },
    },
    xaxis: {
        type: 'datetime',
        categories: [
            '2022-11-01',
            '2022-11-08',
            '2022-11-15',
            '2022-11-22',
            '2022-11-29',
            '2022-12-06',
            '2022-12-13',
            '2022-12-20',
            '2022-12-27',
            '2023-01-03',
            '2023-01-10',
            '2023-01-17',
            '2023-01-24',
            '2023-01-31',
            '2023-02-07',
            '2023-02-14',
            '2023-02-21',
            '2023-02-28',
            '2023-03-07',
            '2023-03-14',
            '2023-03-21',
            '2023-03-28',
            '2023-04-04',
            '2023-04-11',
            '2023-04-18',
            '2023-04-25',
            '2023-05-02',
            '2023-05-09',
            '2023-05-16',
            '2023-05-23',
            '2023-05-30',
        ],

        labels: {
            style: {
                fontSize: '18px',
                fontFamily: 'Roboto',
            },
        },
    },
    tooltip: {
        style: {
            fontSize: '0.875rem',
            fontFamily: 'Arial, Helvetica, sans-serif',
        },
        onDatasetHover: {
            highlightDataSeries: true,
        },
        x: {
            show: true,
            // format: "dd.MM.yyyy",
        },
    },
    grid: {
        show: true,
        borderColor: '#E5E4E5',
        strokeDashArray: 0,
        position: 'back',
        xaxis: {
            lines: {
                show: true,
            },
        },
        yaxis: {
            lines: {
                show: true,
            },
        },
        row: {
            colors: undefined,
            opacity: 0.5,
        },
        column: {
            colors: undefined,
            opacity: 0.5,
        },
        padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
        },
    },
};

export const defaultReportConsolidatedRows: IDictionary[] = [
    {
        codeSDR: '001',
        name: 'Выполнение основных инженерных изысканий',
        data: ['21.08.2023/\n21.08.2023', '21.08.2023/\n21.08.2023', '100.0'],
    },
    {
        codeSDR: '002',
        name: 'Выполнение основных инженерных изысканий',
        data: ['22.08.2023/\n22.08.2023', '22.08.2023/\n22.08.2023', '100.0'],
    },
    {
        codeSDR: '003',
        name: 'Выполнение основных инженерных изысканий',
        data: ['23.08.2023/\n23.08.2023', '23.08.2023/\n23.08.2023', '100.0'],
    },
    {
        codeSDR: '004',
        name: 'Выполнене основных инженерных изысканий',
        data: ['24.08.2023/\n24.08.2023', '24.08.2023/\n24.08.2023', '100.0'],
    },
    {
        codeSDR: '005',
        name: 'Выполнение основных инженерных изысканий',
        data: ['25.08.2023/\n25.08.2023', '25.08.2023/\n25.08.2023', '100.0'],
    },
];

export const defaultReportConsolidatedRows2: IDictionary[] = [
    {
        codeSDR: '006',
        name: 'Выполнение основных инженерных изысканий',
        data: ['26.08.2023/\n26.08.2023', '26.08.2023/\n26.08.2023', '100.0'],
    },
    {
        codeSDR: '007',
        name: 'Выполнение основных инженерных изысканий',
        data: ['27.08.2023/\n27.08.2023', '27.08.2023/\n27.08.2023', '100.0'],
    },
    {
        codeSDR: '008',
        name: 'Выполнение основных инженерных изысканий',
        data: ['28.08.2023/\n28.08.2023', '28.08.2023/\n28.08.2023', '100.0'],
    },
    {
        codeSDR: '009',
        name: 'Выполнение основных инженерных изысканий',
        data: ['29.08.2023/\n29.08.2023', '29.08.2023/\n29.08.2023', '100.0'],
    },
    {
        codeSDR: '010',
        name: 'Выполнение основных инженерных изысканий',
        data: ['30.08.2023/\n30.08.2023', '30.08.2023/\n30.08.2023', '100.0'],
    },
];

export const defaultReportConsolidatedCharts: IDictionary[] = [
    {
        name: '3.0 Строительство ул. Новая.',
        id: 0,
        rows: defaultReportConsolidatedRows,
    },
    {
        name: '3.1 Строительство ул. Новая.',
        id: 1,
        rows: defaultReportConsolidatedRows2,
    },
    {
        name: '3.2 Строительство ул. Новая.',
        id: 2,
        rows: defaultReportConsolidatedRows,
    },
    {
        name: '3.3 Строительство ул. Новая.',
        id: 3,
        rows: defaultReportConsolidatedRows2,
    },
    {
        name: '3.4 Строительство ул. Новая.',
        id: 4,
        rows: defaultReportConsolidatedRows,
    },
];

export const defaultCities: IPMCity[] = [
    {
        id: 1,
        name: 'Уфа',
        projects: [
            {
                id: 1,
                name: '02А0 Конди Литер 3',
                code: '02А0',
                status: 1,
                fileUrl: '',
                chartTypes: [
                    {
                        id: 1,
                        name: 'Сводный',
                        charts: [
                            {
                                id: 1,
                                status: 1,
                                stage: 'Предпроектная проработка, МП, ППТ, ПМТ',
                                name: '02А0 Сводный Конди Литер 1',
                                startedAt: '2023-08-14T07:34:14.000000Z',
                                managerId: 141,
                                designerId: 141,
                                calendarType: 5,
                                createdAt: '2023-08-14T07:34:14.000000Z',
                                updatedAt: '2023-08-14T07:34:14.000000Z',
                                projectId: 1,
                                type: 1,
                            },
                            {
                                id: 2,
                                status: 2,
                                stage: 'Предпроектная проработка, МП, ППТ, ПМТ',
                                name: '02А0 Сводный Конди Литер 1',
                                startedAt: '2023-08-14T07:34:14.000000Z',
                                managerId: 141,
                                designerId: 141,
                                calendarType: 5,
                                createdAt: '2023-08-14T07:34:14.000000Z',
                                updatedAt: '2023-08-14T07:34:14.000000Z',
                                projectId: 1,
                                type: 1,
                            },
                        ],
                    },
                    {
                        id: 2,
                        name: 'Проектирование',
                        charts: [
                            {
                                id: 3,
                                status: 3,
                                stage: 'Основные ИИ, Стадия П, Экспертиза РС',
                                name: '02А0 Проектирование Конди Литер 1',
                                startedAt: '2023-08-14T07:34:14.000000Z',
                                managerId: 141,
                                designerId: 141,
                                calendarType: 5,
                                createdAt: '2023-08-14T07:34:14.000000Z',
                                updatedAt: '2023-08-14T07:34:14.000000Z',
                                projectId: 1,
                                type: 1,
                            },
                        ],
                    },
                    {
                        id: 3,
                        name: 'Строительство',
                        charts: [
                            {
                                id: 4,
                                status: 4,
                                stage: 'Предпроектная проработка, МП, ППТ, ПМТ',
                                name: '02А0 Строительство Конди Литер 1',
                                startedAt: '2023-08-14T07:34:14.000000Z',
                                managerId: 141,
                                designerId: 141,
                                calendarType: 5,
                                createdAt: '2023-08-14T07:34:14.000000Z',
                                updatedAt: '2023-08-14T07:34:14.000000Z',
                                projectId: 1,
                                type: 1,
                            },
                        ],
                    },
                    {
                        id: 4,
                        name: 'Тендеры',
                        charts: [
                            {
                                id: 5,
                                status: 1,
                                stage: 'Основные ИИ, Стадия П, Экспертиза РС',
                                name: '02А0 Тендеры Конди Литер 1',
                                startedAt: '2023-08-14T07:34:14.000000Z',
                                managerId: 141,
                                designerId: 141,
                                calendarType: 5,
                                createdAt: '2023-08-14T07:34:14.000000Z',
                                updatedAt: '2023-08-14T07:34:14.000000Z',
                                projectId: 1,
                                type: 1,
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

export const defaultProjects: IDictionary[] = [
    {
        id: 1,
        name: '02А0 Конди Литер 3',
        code: '02А0',
        statuses: {
            summary: {
                planned: 10,
                approved: 20,
                stopped: 30,
                completed: 40,
            },
            designing: {
                planned: 10,
                approved: 20,
                stopped: 30,
                completed: 40,
            },
            construction: {
                planned: 10,
                approved: 20,
                stopped: 30,
                completed: 40,
            },
            tenders: {
                planned: 10,
                approved: 20,
                stopped: 30,
                completed: 40,
            },
        },
    },
];
