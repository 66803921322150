export default class DataTransferExtractor {
    private _dataTransfer: DataTransfer;
    private _containsFiles: boolean;

    constructor(dataTransfer: DataTransfer) {
        this._dataTransfer = dataTransfer;
        this._containsFiles = this.containsFiles(dataTransfer);
    }

    public extractRootFiles(): File[] {
        const files = [];
        const dataTransfer = this._dataTransfer;

        if (this._containsFiles) {
            const items = dataTransfer.items;

            if (items?.length) {
                for (const item of items) {
                    if (item.webkitGetAsEntry == null) {
                        continue;
                    }

                    const entry = item.webkitGetAsEntry();

                    if (entry?.isFile) {
                        const file = item.getAsFile();

                        if (file) {
                            files.push(file);
                        }
                    }
                }
            } else {
                for (let index = 0; index < dataTransfer.files.length; index++) {
                    files[index] = dataTransfer.files[index];
                }
            }
        }

        return files;
    }

    public containsFiles(dataTransfer: DataTransfer): boolean {
        for (const type of dataTransfer.types) {
            if (type === 'Files') {
                return true;
            }
        }

        return false;
    }
}
