import MoneyBadger from '@/utils/MoneyBadger';
import { getProgressColorClassName } from '@/utils/Colors';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class VProgressMixin extends Vue {
    @Prop({ type: Number, default: 100 })
    public total!: number;

    @Prop({ type: Number, default: 0 })
    public value!: number;

    @Prop({ type: Number, default: 0 })
    public progress!: number;

    @Prop({ type: Boolean, default: false })
    public multiColors!: boolean;

    public get completed() {
        // If progress is exists then return it.
        if (this.progress) {
            return this.progress;
        }

        return MoneyBadger.getProgress(this.value, this.total);
    }

    public get progressColor() {
        return this.multiColors ? getProgressColorClassName(this.progress) : {};
    }
}
